/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable prefer-const */
/* eslint-disable object-shorthand */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/self-closing-comp */
import React from 'react';
import {ContentHeader, Button} from '@components';
import Chart from 'react-apexcharts'

const MinuteOfMeeting = () => {

  return (
    <div>
      <ContentHeader title="Minute Of Meeting" />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className='row'>
                <div className='col-4'>
                  <div className='row'>
                    <div className='col-10'>
                      <input className='form-control' type="date" name="date" id="date" />
                    </div>
                    <div className='col-2'>
                      <button className='btn btn-primary' type="submit">set</button>
                    </div>
                  </div>
                </div>
                <div className='col-6' />
                <div className='col-2'>
                  <button className='float-right btn btn-success' type="submit">Download Excel</button>
                </div>
              </div>
              <br />

              <div className="table-responsive " style={{height:'500px', width:"100%", overflowY: 'scroll', overflowX: 'scroll'} }>
                  <table className="table table-striped table-bordered table-sm">
                      <thead className="text-nowrap">
                          <tr>
                              <th>No</th>
                              <th>Subject</th>
                              <th>Description</th>
                              <th>Action</th>
                              <th>Objective</th>
                              <th>PIC</th>
                              <th>Priority</th>
                              <th>Start Date</th>
                              <th>Target Date</th>
                              <th>Status</th>
                              <th>Remarks</th>
                          </tr>
                      </thead>
                      <tbody >
                          <tr>
                              <td>1</td>
                              <td>Pembangunan Matriks EBITDA masing-masing Instalasi Ranap, Rajal, Kamar Bedah</td>
                              <td>Kesulitan dalam menentukan Biaya Dokter dan Nakes</td>
                              <td>Data Belanja Pegawai dari anggaran perbulan dibagi dengan jumlah hari kerja dibagi jumlah pasien perbulan untuk masing-masing Instalasi Ranap, Rajal, Kamar Bedah</td>
                              <td>Biaya Dokter per-hari per-pasien diketahui</td>
                              <td>Bagian Anggaran dan PPC Instalasi </td>
                              <td>1</td>
                              <td>09/23/2022</td>
                              <td>09/27/2022</td>
                              <td>Open</td>
                              <td></td>
                          </tr>
                          <tr>
                              <td>2</td>
                              <td>Pembangun Matriks EBITDA Rencana masing-masing Instalasi Ranap, Rajal, Kamar Bedah</td>
                              <td>Kesulitan dalam menentukan Biaya BMHP  Secara Detail</td>
                              <td>Bagian Anggaran dan Farmasi harus memberikan Data Biaya BMHP standard untuk Instalasi Rawat Inap secara Detail</td>
                              <td>EBITDA Instalasi Ranap, Rajal, Kamar Bedah bisa didapat</td>
                              <td>Bagian Anggaran dan Instalasi Farmasi</td>
                              <td>1</td>
                              <td>09/23/2022</td>
                              <td>09/27/2022</td>
                              <td>Open</td>
                              <td>1. Terkait Standar Unit Harga BMHP Secara Detail <br/>
                                  2. Kordinasi antara Bagian Anggaran dan Farmasi untuk menetapkannya</td>
                          </tr>
                          <tr>
                              <td>3</td>
                              <td>Konsumsi BMHP Harian Instalasi Ranap, Rajal, Kamar Bedah</td>
                              <td>Kesulitan dalam Pencataan Penggunaan BMHP Harian Ranap, Rajal, Ruang Operasi</td>
                              <td>Membuat Sistem pencataan penggunaan BHMP yang efisien secara Harian di Ranap, Rajal, Ruang Operasi</td>
                              <td>Konsumsi BMHP Harian diperoleh secara akurat</td>
                              <td>Instalasi Farmasi</td>
                              <td>1</td>
                              <td>09/23/2022</td>
                              <td>09/30/2022</td>
                              <td>Open</td>
                              <td>Terkait Jumlah/Volume BMHP Harian</td>
                          </tr>
                          <tr>
                              <td>4</td>
                              <td>Konsumsi ATK Harian Instalasi Ranap, Rajal, Kamar Bedah</td>
                              <td>Kesulitan Pencataan Penggunaan dan Biaya ATK Harian Ranap, Rajal, Ruang Operasi</td>
                              <td>Membuat Sistem pencataan penggunaan ATK yang efisien secara Harian di Ranap, Rajal, Ruang Operasi</td>
                              <td>Konsumsi ATK Harian diperoleh secara akurat</td>
                              <td>Bagian Umum</td>
                              <td>1</td>
                              <td>09/23/2022</td>
                              <td>09/30/2022</td>
                              <td>Open</td>
                              <td></td>
                          </tr>
                          <tr>
                              <td>5</td>
                              <td>Biaya administrasi (TU) masing-masing Instalasi</td>
                              <td>Diperlukan Detail Data Biaya administrasi (TU) harian untuk masing-masing Instalasi dalam membangun Matriks EBITDA Instalasi</td>
                              <td>Menetapkan Data Biaya administrasi (TU) harian masing-masing Instalasi dan memberikannya kepada masing-masing Instalasi</td>
                              <td>Matriks EBITDA Instalasi Terbentuk</td>
                              <td>Bagian anggaran dan SDM</td>
                              <td>1</td>
                              <td>09/23/2022</td>
                              <td>09/28/2022</td>
                              <td>Open</td>
                              <td>Koordinasi antara Bagian Anggaran dan SDM Untuk menetapkannya</td>
                          </tr>
                      </tbody>
                  </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MinuteOfMeeting;
