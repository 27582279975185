/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable prefer-const */
/* eslint-disable object-shorthand */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import {ContentHeader, Button} from '@components';
import Chart from 'react-apexcharts'

const YearlyEbitdaTrend = () => {

  return (
    <div>
      <ContentHeader title="Yearly EBITDA Trend" />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className='row'>
                <div className='col-4'>
                  <div className='row'>
                    <div className='col-5'>
                      <input className='form-control' type="date" name="date" id="date" />
                    </div>
                    <div className='col-5'>
                      <select className='form-control' name="dept" id="dept">
                        <option value="Rawat Jalan">Rawat Jalan</option>
                        <option value="Rawat Inap">Rawat Inap</option>
                        <option value="Kamar Bedah">Kamar Bedah</option>
                        <option value="Farmasi">Farmasi</option>
                      </select>
                    </div>
                    <div className='col-2'>
                      <button className='btn btn-primary' type="submit">set</button>
                    </div>
                  </div>
                </div>
                <div className='col-6' />
                <div className='col-2'>
                  <button className='float-right btn btn-success' type="submit">Download Excel</button>
                </div>
              </div>
              <br />
              {/* @ts-ignore */}
              <Chart 
                height={450}
                options={{
                  chart:{
                    toolbar:{
                      export:{
                        csv:{
                          filename: 'yearly-ebitda-trend',
                        },
                        svg: {
                          filename: 'yearly-ebitda-trend',
                        },
                        png: {
                          filename: 'yearly-ebitda-trend',
                        }
                      }
                    }
                  },
                  title : {
                    text: 'EBITDA Target vs EBITDA Actual'
                  },
                  labels: [
                    "2021-01",
                    "2021-02",
                    "2021-03",
                    "2021-04",
                    "2021-05",
                    "2021-06",
                    "2021-07",
                    "2021-08",
                  ],
                  colors: ['#0000FF', '#FF0000'],
                  fill: {
                    colors: ['#0000FF', '#FF0000'],
                  },
                  xaxis: {
                    type: 'datetime'
                  },
                }} 
                series={
                  [
                    {
                      name: 'EBITDA Target',
                      type: 'column',
                      data: Array.from(new Array(31),(val,index)=>10),
                    }, 
                    {
                      name: 'EBITDA Actual',
                      type: 'column',
                      data: Array.from(new Array(15),(val,index)=>index),
                    }, 
                  ]
                } 
              />
              <br />
              {/* @ts-ignore */}
              <Chart 
                height={450}
                options={{
                  chart:{
                    toolbar:{
                      export:{
                        csv:{
                          filename: 'yearly-ebitda-trend',
                        },
                        svg: {
                          filename: 'yearly-ebitda-trend',
                        },
                        png: {
                          filename: 'yearly-ebitda-trend',
                        }
                      }
                    }
                  },
                  title : {
                    text: 'EBITDA Target vs EBITDA Actual'
                  },
                  labels: [
                    "2021-01",
                    "2021-02",
                    "2021-03",
                    "2021-04",
                    "2021-05",
                    "2021-06",
                    "2021-07",
                    "2021-08",
                  ],
                  colors: ['#0000FF', '#FF0000'],
                  fill: {
                    colors: ['#0000FF', '#FF0000'],
                  },
                  xaxis: {
                    type: 'datetime'
                  },
                }} 
                series={
                  [
                    {
                      name: 'EBITDA Target',
                      type: 'line',
                      data: Array.from(new Array(8),(val,index)=>index*10),
                    }, 
                    {
                      name: 'EBITDA Actual',
                      type: 'line',
                      data: Array.from(new Array(8),(val,index)=>index*9),
                    }, 
                  ]
                } 
              />
              <br />
              {/* @ts-ignore */}
              <Chart 
                height={450}
                options={{
                  chart:{
                    toolbar:{
                      export:{
                        csv:{
                          filename: 'yearly-ebitda-trend',
                        },
                        svg: {
                          filename: 'yearly-ebitda-trend',
                        },
                        png: {
                          filename: 'yearly-ebitda-trend',
                        }
                      }
                    }
                  },
                  title : {
                    text: 'EBITDA Margin August 21'
                  },
                  labels: [
                    "2021-01",
                    "2021-02",
                    "2021-03",
                    "2021-04",
                    "2021-05",
                    "2021-06",
                    "2021-07",
                    "2021-08",
                  ],
                  colors: ['#0000FF', '#FF0000'],
                  fill: {
                    colors: ['#0000FF', '#FF0000'],
                  },
                  xaxis: {
                    type: 'datetime'
                  },
                }} 
                series={
                  [
                    {
                      name: 'EBITDA Margin',
                      type: 'line',
                      data: Array.from(new Array(8),(val,index)=> Math.round( Math.random() *100)),
                    }, 
                  ]
                } 
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default YearlyEbitdaTrend;
