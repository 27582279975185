/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable prefer-const */
/* eslint-disable object-shorthand */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-multi-str */
/* eslint-disable prefer-template */
/* eslint-disable import/no-duplicates */
/* eslint-disable no-plusplus */
import React from 'react';
import { useEffect, useState } from "react";
import {ContentHeader, Button} from '@components';
import useGoogleCharts from '@app/hooks/useGoogleCharts';
import { Chart } from "react-google-charts";

const example = (title: String) => {
  return ' \
  <table class="table table-sm table-bordered">\
    <tbody>\
      <tr>\
        <th rowspan="2">X</th>\
        <th colspan="3">'+title+'</th>\
      </tr>\
      <tr>\
        <td>Actual</td>\
        <td>Target</td>\
        <td>Comparison</td>\
      </tr>\
      </thead>\
      <tbody>\
        <tr>\
          <td>EBITDA<br>Margin</td>\
          <td>40.7%</td><td>38.5%</td>\
          <td>105.6%</td>\
        </tr>\
        <tr>\
          <td>EBITDA</td>\
          <td>162560</td>\
          <td>150620</td>\
          <td>107.9%</td>\
        </tr>\
        <tr>\
          <td>Revenue</td>\
          <td>399500</td>\
          <td>391000</td>\
          <td>102.2%</td>\
        </tr>\
        <tr>\
          <td>let. Cost</td>\
          <td>96410</td>\
          <td>90500</td>\
          <td>106.5%</td>\
        </tr>\
        <tr>\
          <td>Fixed Cost</td>\
          <td>128380</td>\
          <td>125110</td>\
          <td>102.6%</td>\
        </tr>\
        <tr>\
          <td>Ind. Cost</td>\
          <td>12150</td>\
          <td>24770</td>\
          <td>49.1%</td>\
        </tr>\
    </tbody>\
  </table>'
}

function OrgChart ({google}) {
  const [chart, setChart] = useState(null);

  useEffect(() => {
    if (google && !chart) {
      // Create the data table.
      const data = new google.visualization.DataTable();
      data.addColumn('string', 'Name');
      data.addColumn('string', 'Manager');
      data.addColumn('string', 'ToolTip');
      data.addRows([
        [
          {
            v: 'POHON EBITDA',
            f: ''
          },
          '', ''
        ],
        [
          {
            v: 'CICENDO',
            f: example('EBITDA RS Cicendo'),
          },
          'POHON EBITDA', ''
        ],
        [
          {
            v: 'Pelayanan Medik dan Pelayanan Penunjang',
            f: example('Pelayanan Medik dan Pelayanan Penunjang')
          },
          'CICENDO', ''
        ],
        [
          {
            v: 'Cost Center',
            f: example('Cost Center')
          },
          'Pelayanan Medik dan Pelayanan Penunjang', ''
        ],
        [
          {
            v: 'Pelayanan Medik',
            f: example('Pelayanan Medik')
          },
          'Cost Center', ''
        ],
        [
          {
            v: 'Pelayanan Penunjang',
            f: example('Pelayanan Penunjang')
          },
          'Cost Center', ''
        ],
        [
          {
            v: 'Revenue Center',
            f: example('Revenue Center')
          },
          'Pelayanan Medik dan Pelayanan Penunjang', ''
        ],
        [
          {
            v: 'Instalasi Rawat Jalan',
            f: example('Instalasi Rawat Jalan')
          },
          'Revenue Center', ''
        ],
        [
          {
            v: 'Instalasi Rawat Inap',
            f: example('Instalasi Rawat Inap')
          },
          'Revenue Center', ''
        ],
        [
          {
            v: 'Instalasi Kamar Bedah',
            f: example('Instalasi Kamar Bedah')
          },
          'Revenue Center', ''
        ],
        [
          {
            v: 'Instalasi Farmasi',
            f: example('Instalasi Farmasi')
          },
          'Revenue Center', ''
        ],
        // [
        //   {
        //     v: 'Instalasi Gawat Darurat',
        //     f: example('Instalasi Gawat Darurat')
        //   },
        //   'Revenue Center', ''
        // ],
        // [
        //   {
        //     v: 'Instalasi Eksekutif Lasik',
        //     f: example('Instalasi Eksekutif Lasik')
        //   },
        //   'Revenue Center', ''
        // ],
        // [
        //   {
        //     v: 'Instalasi Eksekutif Paviliun',
        //     f: example('Instalasi Eksekutif Paviliun')
        //   },
        //   'Revenue Center', ''
        // ],
        // [
        //   {
        //     v: 'Instalasi Diagnostik',
        //     f: example('Instalasi Diagnostik')
        //   },
        //   'Revenue Center', ''
        // ],
        // [
        //   {
        //     v: 'Instalasi Radiologi',
        //     f: example('Instalasi Radiologi')
        //   },
        //   'Revenue Center', ''
        // ],
        // [
        //   {
        //     v: 'Instalasi Laboratorium',
        //     f: example('Instalasi Laboratorium')
        //   },
        //   'Revenue Center', ''
        // ],
        // [
        //   {
        //     v: 'Instalasi Daycare',
        //     f: example('Instalasi Daycare')
        //   },
        //   'Revenue Center', ''
        // ],
        // [
        //   {
        //     v: 'Diklat',
        //     f: example('Diklat')
        //   },
        //   'Revenue Center', ''
        // ],
        // [
        //   {
        //     v: 'Instalasi Gizi',
        //     f: example('Instalasi Gizi')
        //   },
        //   'Revenue Center', ''
        // ],
        // [
        //   {
        //     v: 'Farmasi Retail',
        //     f: example('Farmasi Retail')
        //   },
        //   'Revenue Center', ''
        // ],
        [
          {
            v: 'Direktorat SDM, Pendidikan, dan Penelitian',
            f: example('Direktorat SDM, Pendidikan, dan Penelitian')
          },
          'CICENDO', ''
        ],
        [
          {
            v: 'Diklit',
            f: example('Diklit')
          },
          'Direktorat SDM, Pendidikan, dan Penelitian', ''
        ],
        [
          {
            v: 'SDM',
            f: example('SDM')
          },
          'Direktorat SDM, Pendidikan, dan Penelitian', ''
        ],
        [
          {
            v: 'Direktorat Keuangan dan Barang Milik Negara',
            f: example('Direktorat Keuangan dan Barang Milik Negara')
          },
          'CICENDO', ''
        ],
        [
          {
            v: 'Anggaran',
            f: example('Anggaran')
          },
          'Direktorat Keuangan dan Barang Milik Negara', ''
        ],
        [
          {
            v: 'Akuntansi & BMN',
            f: example('Akuntansi & BMN')
          },
          'Direktorat Keuangan dan Barang Milik Negara', ''
        ],
        [
          {
            v: 'Direktorat Perencanaan dan Umum',
            f: example('Direktorat Perencanaan dan Umum')
          },
          'CICENDO', ''
        ],
        [
          {
            v: 'Organisasi dan Umum',
            f: example('Organisasi dan Umum')
          },
          'Direktorat Perencanaan dan Umum', ''
        ],
        [
          {
            v: 'Perencanaan dan Evaluasi',
            f: example('Perencanaan dan Evaluasi')
          },
          'Direktorat Perencanaan dan Umum', ''
        ],
      ]);

      let options = {
        title:'Pohon EBITDA',
        allowHtml: true,
        allowCollapse: true,
      };

      // Instantiate and draw our chart, passing in some options.
      console.log(google.visualization)
      const chart = new google.visualization.OrgChart(document.getElementById('orgChart'));
      google.visualization.events.addOneTimeListener(chart, 'ready', function () {
        // loop rows in reverse order
        for (let i = data.getNumberOfRows() - 1; i >= 1; i--) {
          // determine if node has children
          if (chart.getChildrenIndexes(i).length > 0) {
            // collapse row
            chart.collapse(i, true);
          }
        }
      });

      chart.draw(data, options);

      setChart(chart);
    }
  }, [google, chart]);


  return (
    <>
      {!google && <>Loading</>}
      <div id="orgChart" className={!google ? 'd-none' : ''} />
    </>
  )
}

const EbitdaTree = () => {
  const google = useGoogleCharts();

  return (
    <div>
      <ContentHeader title="Pohon EBITDA" />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div style={{backgroundColor: '#F0F0F0', height:'500px', width:"100%", overflowY: 'scroll', overflowX: 'scroll'} } >
                <OrgChart google={google} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EbitdaTree;
