/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable prefer-const */
/* eslint-disable object-shorthand */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import {ContentHeader, Button} from '@components';
import Chart from 'react-apexcharts'

const CostDistribution = () => {

  return (
    <div>
      <ContentHeader title="Cost Distribution" />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className='row'>
                <div className='col-4'>
                  <div className='row'>
                    <div className='col-5'>
                      <input className='form-control' type="date" name="date" id="date" />
                    </div>
                    <div className='col-5'>
                      <select className='form-control' name="dept" id="dept">
                        <option value="Rawat Jalan">Rawat Jalan</option>
                        <option value="Rawat Inap">Rawat Inap</option>
                        <option value="Kamar Bedah">Kamar Bedah</option>
                        <option value="Farmasi">Farmasi</option>
                      </select>
                    </div>
                    <div className='col-2'>
                      <button className='btn btn-primary' type="submit">set</button>
                    </div>
                  </div>
                </div>
                <div className='col-6' />
                <div className='col-2'>
                  <button className='float-right btn btn-success' type="submit">Download Excel</button>
                </div>
              </div>
              <br />
              {/* @ts-ignore */}
              <Chart 
                height={450}
                options={{
                  chart:{
                    stacked: true,
                    toolbar:{
                      export:{
                        csv:{
                          filename: 'cost-distribution',
                        },
                        svg: {
                          filename: 'cost-distribution',
                        },
                        png: {
                          filename: 'cost-distribution',
                        }
                      }
                    }
                  },
                  title : {
                    text: 'Cost Distribution'
                  },
                  labels: [
                    "Cost",
                    "Revenue",
                  ],
                  stroke:{
                    show: false
                  }
                }} 
                series={
                  [
                    {
                      name: 'Variable Cost',
                      type: 'column',
                      data: [9936542, 0],
                    }, 
                    {
                      name: 'Fixed Cost',
                      type: 'column',
                      data: [58738856, 0],
                    }, 
                    {
                      name: 'Indirect Cost',
                      type: 'column',
                      data: [362900, 0],
                    }, 
                    {
                      name: 'Revenue',
                      type: 'column',
                      data: [0, 279619000],
                    }, 
                  ]
                } 
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CostDistribution;
