/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable prefer-const */
/* eslint-disable object-shorthand */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import {ContentHeader, Button} from '@components';
import Chart from 'react-apexcharts'

const CapacityMatrix = () => {

  return (
    <div>
      <ContentHeader title="Capacity Matrix" />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className='row'>
                <div className='col-4'>
                  <div className='row'>
                    <div className='col-5'>
                      <input className='form-control' type="date" name="date" id="date" />
                    </div>
                    <div className='col-5'>
                      <select className='form-control' name="dept" id="dept">
                        <option value="Rawat Jalan">Rawat Jalan</option>
                        <option value="Rawat Inap">Rawat Inap</option>
                        <option value="Kamar Bedah">Kamar Bedah</option>
                        <option value="Farmasi">Farmasi</option>
                      </select>
                    </div>
                    <div className='col-2'>
                      <button className='btn btn-primary' type="submit">set</button>
                    </div>
                  </div>
                </div>
                <div className='col-6' />
                <div className='col-2'>
                  <button className='float-right btn btn-success' type="submit">Download Excel</button>
                </div>
              </div>
              <br />
              {/* @ts-ignore */}
              <Chart 
                height={450}
                options={{
                  chart:{
                    toolbar:{
                      export:{
                        csv:{
                          filename: 'capacity-matrix',
                        },
                        svg: {
                          filename: 'capacity-matrix',
                        },
                        png: {
                          filename: 'capacity-matrix',
                        }
                      }
                    }
                  },
                  title : {
                    text: 'Capacity Matrix'
                  },
                  labels: ['Registrasi', 'Sign In', 'Operating Theater', 'Recovery Room'],
                  colors: ['#FF0000', '#00FF00', '#0000FF'],
                  fill: {
                    colors: ['#FF0000', '#00FF00', '#0000FF'],
                  }
                }} 
                series={
                  [
                    {
                      name: 'Capacity per Process',
                      type: 'column',
                      data: [12	,6	,2	,2],
                    }, 
                    {
                      name: 'Maximum Capacity',
                      type: 'line',
                      data: [2	,2	,2	,2]
                    },
                    {
                      name: 'Target Capacity',
                      type: 'line',
                      data: [7	,7	,7	,7]
                    },
                  ]
                } 
                />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CapacityMatrix;
